import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageCenter } from '../../components/imageelements'
import { Helmet } from "react-helmet"
import AudioSet from '../../components/audioset'
import { AdContainer } from '../../components/adstyling'
import { MainBodySection } from '../../components/generalstyling'
import Bibliography from '../../components/bibliography.js'


const bibliography = [
  {name: 'The New Grove Dictionary of Opera', writer: 'Stanley Sadie', url: 'https://amzn.to/2VhOve7'},
]

// markup
const VoicesPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Operatic Voices | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operaabc/voices" />
          <meta name="description" content="Describing opera singers can seem a bit like voodoo. Learn the basics of the Fach system here" />
        </Helmet>
        <Hero title="Opera Voices" subtitle="The basic fachs"/>
        <MainBodySection>

          <Header title="The Fach System"/>

          <p>Operatic voices can be classified by a variety of means. At base, we define singers by the vocal range of their voice (basically what notes they can sing), but opera has developed a range of conventions for grouping singers with particular vocal styles as well. The German Fach system is the predominant one and the one we cover here. This system gets pretty specific, so frequently singers will sing across the sub-classifications; for example, a singer might sing a lyric tenor role one month and a spinto tenor one the next.</p>
          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <Header title="Soprano"/>

          <ImageCenter>
            <StaticImage src="../../images/abcs/voices/SopranoKeyboard.jpg" alt="Standard Soprano range on a keyboard" title="Standard Soprano range on a keyboard"/>
            <figcaption>Standard Soprano range on a keyboard</figcaption>
          </ImageCenter>

          <p>The soprano is the highest female voice type, and they often take the leading female role. The vocal range for an operatic soprano is roughly from middle C up to the C two octaves above, though plenty of music, particularly for coloratura sopranos, ascends even further.</p>

          <p>Sopranos are split into five major categories:</p>

          <SubHeader title="Coloratura"/>

          <p>The coloratura soprano is capable of seemingly superhuman feats. The voice is extremely agile, firing out fast-paced coloratura sections that ascend as high as the 3rd F above middle C (and in a few cases even higher). These roles have existed from Baroque through 20th Century opera. An outstanding example is Lucia in Donizetti’s Lucia di Lammermoor and an excerpt from her mad scene can be found below.</p>

          <AudioSet audiosrc="/audio/voices/Mad_Sutherland.mp3" desc="The Mad scene from Lucia di Lammermoor sung by the late Joan Sutherland"/>

        </MainBodySection>


        <div id="ezoic-pub-ad-placeholder-103"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <SubHeader title="Soubrette"/>

          <p>A soubrette soprano refers as much to an archetype of character as a voice type. These are cheeky, coquettish parts, sung by singers with sweet, bright voices. The tessitura of these parts can sometimes be pretty high but without an excess of coloratura.</p>

          <AudioSet audiosrc="/audio/voices/Gretel_Damrau.mp3" desc="An excerpt from Hansel und Gretel sung by Diana Damrau"/>

          <SubHeader title="Lyric"/>

          <p>The lyric soprano usually possesses a fuller, richer sound than the soubrette and tends to have a more mature quality. Their tessitura generally lies higher than the soubrette but lower than the coloratura heading up to the D two octaves and a tone above middle C on occasion. Some of the loveliest music is given to these singers, Liu gets this haunting tune near the end of <Link to="/operas/turandot"><i>Turandot</i></Link></p>

          <AudioSet audiosrc="/audio/voices/Signora_Martinez.mp3" desc="'Tanto amore segreto' from Turandot sung by Ana Maria Martinez"/>

          <SubHeader title="Spinto"/>

          <p>The spinto soprano gets a good deal of the really plum roles in opera, particularly in the Italian Romantic tradition of <Link to="/composers/verdi">Verdi</Link> and <Link to="/composers/puccini">Puccini</Link>. Partly for this reason, lyric and dramatic sopranos frequently take on these roles whether naturally suited or not (and more than a few lyric sopranos have shortened their careers by taking on heavy spinto roles). These roles call for the light, brilliant high notes of the lyric soprano but with more heft in the big climaxes (spinto translates as "pushed"). Below you’ll find an excerpt from Tatyana’s passionate love letter scene from <Link to="/operas/onegin"><em>Eugene Onegin</em></Link>.
          </p>

          <AudioSet audiosrc="/audio/voices/Letter_Piec.mp3" desc="An excerpt from Eugene Onegin sung by Adrianne Pieczonka"/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-104"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>          

          <SubHeader title="Dramatic"/>

          <p>These are big soprano voices with sufficient heft to be heard over a large orchestra whilst maintaining an evenness across the entire range. Dramatic soprano roles came to the fore in the Romantic era; indeed, there were few genuine dramatic soprano roles before the mid-19th Century. Wagner supplied a range of dramatic soprano roles, such as the colossal part that is Brunnhilde. Below is a tiny clip of Christine Brewer singing the immolation scene from the conclusion of Wagner's Ring Cycle</p>


          <AudioSet audiosrc="/audio/voices/Immo_Brewer.mp3" desc="An excerpt from Gotterdammerung, Christine Brewer singing Brunnhilde"/>

          <Header title="Mezzo-Soprano"/>

          <ImageCenter>
            <StaticImage src="../../images/abcs/voices/MezzoKeyboard.jpg" alt="Standard Mezzo range on a keyboard" title="Standard Mezzo range on a keyboard"/>
            <figcaption>Standard Mezzo-soprano range on a keyboard</figcaption>
          </ImageCenter>

          <p>Singing slightly lower than the soprano, the vocal range of an operatic mezzo-soprano (often abbreviated to just mezzo) spans from the G below middle C to the A two octaves above, though plenty of roles require the voice to stretch above and/or below this.</p>

          <p>Mezzos are too often relegated to supporting roles or villains. What principal roles exist for the mezzo-soprano are most commonly found in French-language operas, Bizet’s <Link to="/operas/carmen">Carmen</Link> probably the most famous mezzo role of them all.</p>


          <AudioSet audiosrc="/audio/voices/Habanera_Garanca.mp3" desc="The Habanera from Carmen sung by Elina Garanca"/>

          <p>Mezzos breakdown into three broad categories:</p>

          <SubHeader title="Coloratura"/>

          <p>The coloratura mezzo-soprano is a fairly small niche. Two periods heavily used this voice type and one of these after the fact. Roles actually written for agile, lower female voices belong to the Bel Canto period, Donizetti and Rossini writing a range of big roles. The other collection of coloratura mezzo roles were originally written for castrati in the Baroque period. However, as we no longer abide by such practices, mezzos have frequently taken these parts in modern times.</p>

          <p>Coloratura mezzo roles require agile runs up to even high C but also call for just as much oompf in the middle and bottom of the mezzo’s range. Below you’ll find a brief excerpt from Rossini’s <Link to="/operas/barbiere">The Barber of Seville</Link>, with Agnes Baltsa singing “Una voce poco fa”, a wonderful example of this sort of singer.</p>


          <AudioSet audiosrc="/audio/voices/UnaVoce_Baltsa.mp3" desc="'Una voce poco fa' from Il Barbiere di Sivilia sung by Agnes Baltsa"/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-105"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <SubHeader title="Lyric"/>

          <p>The lyric mezzo-soprano gets perhaps the least glamorous of roles, a good whack of them are “trouser” parts (women playing men). That classification belies voices that are also normally smooth and rather sexy such as Dorabella in Mozart’s <i>Cosi Fan Tutte</i>. Here's a brief clip from Ann Murray singing "È amore un ladroncello" from that very opera:</p>


          <AudioSet audiosrc="/audio/voices/Amore_Murray.mp3" desc="'È amore un ladroncello' from Cosi Fan Tutte sung by Ann Murray"/>

          <SubHeader title="Dramatic"/>

          <p>Frequently playing mothers or witches, the dramatic mezzo voice is warm, rich and unbeatably loud. This voice type was frequently called for from the middle 1800s onwards. <Link to="/composers/verdi">Verdi</Link> wrote a whole host of dramatic mezzo roles as did Wagner. One of the most vivid can be found in Richard Strauss’s <i>Elektra</i>: the role of Klytemnestra.
          </p>


          <AudioSet audiosrc="/audio/voices/Klyt_Fass.mp3" desc="An excerpt from Elektra, Brigitte Fassbaender singing Klytemnestra"/>

          <Header title="Contralto"/>

          <ImageCenter>
            <StaticImage src="../../images/abcs/voices/ContraltoKeyboard.jpg" alt="Standard Contralto range on a keyboard" title="Standard Contralto range on a keyboard"/>
            <figcaption>Standard Contralto range on a keyboard</figcaption>
          </ImageCenter>

          <p>The contralto voice is the lowest of the female voices and by far and away the rarest. The contralto range is roughly from the F below middle C to a high F one octave above middle C almost exactly matching that of the male countertenor.</p>

          <p> Before the early 1800s (and to some extent ever since) the contralto sound was viewed as unromantic and unsexy, so many of the parts written were older characters, often unpleasant, sometimes humorous. The Russians never saw it that way, however, so what youthful contralto roles there are, are primarily in Russian language operas (think Olga in <Link to="/operas/onegin">Onegin</Link>).</p>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-106"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <p>Worth noting is that the art of contralto singing is dangerously close to extinct. So out of favour that the magnificent Ewa Podleś is close to the only major living contralto (and she’s 63). The lack of singers of this voice type means contralto roles are often taken by mezzos (and even sopranos in some cases).</p>

          <p>Contraltos are divided into three broad categories:</p>

          <SubHeader title="Coloratura"/>

          <p> A light, agile voice that can reach and remain at the highest end of the contralto range whilst managing to sustain a rich and powerful sound. Exceedingly rare but blessed with several significant parts. Rossini actually wrote Rosina (from <Link to="/operas/barbiere">The Barber of Seville</Link>) for a coloratura contralto as well as the title role in <i>La Cenerentola</i>.</p>

          <AudioSet audiosrc="/audio/voices/UnaVoce_Ewa.mp3" desc="'Una voce poco fa' from Il Barbiere di Sivilia sung by Ewa Podleś"/>

          <SubHeader title="Lyric"/>

          <p>Sitting between the coloratura and the dramatic, a flexible but still rich sound. This is the most commonly heard voice within the classification.</p>


          <AudioSet audiosrc="/audio/voices/Hush_Ferrier.mp3" desc="'Hush, She Comes' from The Rape of Lucretia sung by Kathleen Ferrier"/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-107"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <SubHeader title="Dramatic"/>

          <p>Low, dark, heavy and immense. Sadly as rare as the coloratura contralto. So rare we couldn’t find a decent recording anywhere. Please get in touch if you have one!</p>

          <Header title="Countertenor"/>

          <ImageCenter>
            <StaticImage src="../../images/abcs/voices/CountertenorKeyboard.jpg" alt="Standard Countertenor range on a keyboard" title="Standard Countertenor range on a keyboard"/>
            <figcaption>Standard Countertenor range on a keyboard</figcaption>
          </ImageCenter>

          <p>The highest male voice type, roughly equivalent in pitch to mezzo-sopranos. Countertenors were popular in the 17th Century but fell out of fashion until the mid 20th Century, roughly coinciding with a boom in the popularity of Baroque and other early music. The countertenor range is roughly from the G below middle C to a high F one octave above middle C. These male singers achieve this high lying range through the use of their head voice (often called falsetto).</p>

          <p>In opera, countertenors frequently take Baroque roles, particularly those given initially to Castrati. Some modern composers have seized upon this voice type, however, and has been increasingly utilised in contemporary opera, most famously by Britten with the King of the Fairies, Oberon, in <Link to="/operas/dream"><i>A Midsummer Night's Dream</i></Link></p>


          <AudioSet audiosrc="/audio/voices/Bank_Kowalski.mp3" desc="Oberon's 'I know a bank' sung by Jochen Kowalski"/>

          <Header title="Tenor"/>

          <ImageCenter>
            <StaticImage src="../../images/abcs/voices/TenorKeyboard.jpg" alt="Standard Tenor range on a keyboard" title="Standard Tenor range on a keyboard"/>
            <figcaption>Standard Tenor range on a keyboard</figcaption>
          </ImageCenter>

          <p>Tenors frequently take the leading male role (and are said to always get the girl, on stage and off!). The operatic vocal range for a tenor is roughly from the C below middle C to the C above middle C.</p>

          <p> Striking these high Cs is a challenge for many tenors, and one of the pinnacles of high C singing comes in Donizetti’s <i>La Fille du Regiment</i>, in the aria "Ah! mes amis, quel jour de fête!", a clip of which you can hear below.</p>


          <AudioSet audiosrc="/audio/voices/AhMesAmi_Florez.mp3" desc="'Ah! mes amis' sung by Juan Diego Florez"/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-108"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <p>Tenors break down into a range of categories, some more common than others. Below are the four of the most common groupings:</p>

          <SubHeader title="Lyric"/>

          <p>Warm, bright and capable of hitting the highest tenor notes with ease, lyric tenors get some of the most charming operatic roles. A fairly broad category, these singers can range in tonal colour, some much darker and fuller, others lighter and brighter. Below is a short excerpt from the prologue of <i>Les Contes d'Hoffmann</i> by Offenbach. It's sung by Rolando Villazón, who sits at the heavier end of the lyric tenor spectrum.</p>

          <AudioSet audiosrc="/audio/voices/Klein_Villazon.mp3" desc="Hoffmann sung by Rolando Villazón"/>

          <SubHeader title="Spinto"/>

          <p>Similar to the lyric tenor in range but with more heft, particularly towards the top. These roles are far tougher than many people give them credit. The heroic <Link to="/composers/verdi">Verdi</Link> parts icebergs that many a tenor has crashed upon. Radames from Verdi's <i>Aida</i> is one such role and “Celeste Aida” a formidable challenge.</p>

          <AudioSet audiosrc="/audio/voices/Celeste_Domingo.mp3" desc="'Celeste Aida' sung by Placido Domingo"/>

          <SubHeader title="Dramatic"/>

          <p>Big, passionate and powerful, a dramatic tenor is usually spared the blushes of trying to hit a string of high notes but must project a rich sound against potent orchestral forces. The example below is from <Link to="/composers/verdi">Verdi's</Link> <i> Otello</i>, a dark brooding anti-hero requiring a muscular sound like that of Jon Vickers.</p>

          <AudioSet audiosrc="/audio/voices/Otello_Vickers.mp3" desc="The start of 'Niun mi tema' sung by John Vickers"/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-109"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <SubHeader title="Heldentenor"/>

          <p>Literally translates as heroic tenor. This is a vocal class largely introduced by Wagner, a collection of parts with low, almost baritonal, tessitura. They are massive roles, requiring the singer sustain a powerful sound over enormously long periods making them near unsingable. Wagner created a good dozen of these roles, but perhaps the most demanding of all is Siegfried in the Ring Cycle. Here is a short excerpt from a scene in which Siegfried forges his sword (hence the banging).</p>

          <AudioSet audiosrc="/audio/voices/Forge_Jerusalem.mp3" desc="The forging scene from Siegfried sung by Siegfried Jerusalem"/>

          <Header title="Baritone"/>

          <ImageCenter>
            <StaticImage src="../../images/abcs/voices/BaritoneKeyboard.jpg" alt="Standard Baritone range on a keyboard" title="Standard Baritone range on a keyboard"/>
            <figcaption>Standard Baritone range on a keyboard</figcaption>
          </ImageCenter>

          <p>The middle male voice singing in the range from roughly the second G below middle C up to the G above it.</p>

          <SubHeader title="Lyric"/>

          <p>Singing in a range from the A one and a half octaves below middle C to the A just above it, the lyric baritone is a light, fruity, deep male voice. These tend towards comic parts, but they’re not without depth in some cases. Papageno in Mozart’s <Link to="/operas/flute">Die Zauberflöte (The Magic Flute)</Link> gets this delightful aria about his dream woman!</p>

          <AudioSet audiosrc="/audio/voices/DasMad_Degout.mp3" desc="'Ein Mädchen oder Weibchen' sung by Stephan Degout"/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-110"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <SubHeader title="Verdi"/>

          <p>The <Link to="/composers/verdi">Verdi</Link> baritone as you might expect from the name is a voice type specific to Verdi operas. True Verdi baritones are somewhat rare, the roles requiring the singer sing notes at the extremes of both ends of the baritone range and do so with a wealth of round sound. Verdi took Shakespeare's <i>Macbeth</i> and made a riveting opera out of it, the title role a great example of the Verdi baritone.</p>

          <AudioSet audiosrc="/audio/voices/Pieta_Nucci.mp3" desc="'Pieta, rispeto, amore' from Macbeth sung by leo Nucci"/>

          <SubHeader title="Dramatic"/>

          <p>The lowest true baritonal vocal type, dramatic baritones have a similar range to the <Link to="/composers/verdi">Verdi</Link> singers, the G two octaves below middle C upwards, but the tessitura tends to lie lower. The despicable Scarpia from Puccini’s <Link to="/operas/tosca">Tosca</Link> is a prime example of this voice, here is a clip of Bryn Terfel singing the mighty Te Deum.</p>

          <AudioSet audiosrc="/audio/voices/TeDeum_Terfel.mp3" desc="The Te Deum from Tosca sung by Bryn Terfel"/>

          <Header title="Bass"/>

          <ImageCenter>
            <StaticImage src="../../images/abcs/voices/BassKeyboard.jpg" alt="Standard Bass range on a keyboard" title="Standard Bass range on a keyboard"/>
            <figcaption>Standard Bass range on a keyboard</figcaption>
          </ImageCenter>

          <p>The lowest voice of all. The standard operatic bass range is from the E above middle C to the E two octaves below. Some bass singers can go even lower, though this is seldom called for in the standard bass repertoire.</p>

          <SubHeader title="Bass-baritone"/>

          <p>The bass-baritone can sing as low as a bass but just as comfortably in higher-lying tessitura close to the baritone range. This voice type was predominantly written for from the mid 19th Century onwards, but several Mozart roles, written in the era before baritone had even become a vocal type, are commonly given to singers in this class. Here is an excerpt from Strauss's <i>Salome</i>, the great Hans Hotter as Jokanaan</p>

          <AudioSet audiosrc="/audio/voices/Jok_Hotter.mp3" desc="Jokanaan's first appearance in Salome sung by Hans Hotter"/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-111"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <SubHeader title="Buffo"/>

          <p>Buffo bass roles are funny, comic relief, roles found most frequently in Bel Canto works. They are very distinct parts with extensive “patter” singing requirements, the text often a tongue-twisting nightmare of alliteration.</p>

          <p>Though amusing, these are often villainous roles, albeit hopeless, blustering ones, for example Doctor Magnifico in Rossini’s <i>La Cenerentola</i>.</p>

          <AudioSet audiosrc="/audio/voices/Magni_Corbelli.mp3" desc="'Sia qualunque delle figlie' sung by Alessandro Corbelli"/>

          <SubHeader title="Basso profundo"/>

          <p>The lowest voice type in opera is the basso profundo. These singers produce a wall of rich, unending sound with limited vibrato but enormous power. The parts are mostly limited to older male villains, though not exclusively. For example, Sarastro in Mozart’s <Link to="/operas/flute">Die Zauberflöte (The Magic Flute)</Link> is a sage-like leader.</p>

          <AudioSet audiosrc="/audio/voices/Sara_Halfvarson.mp3" desc="Sarastro's 'In diesen heil'gen Hallen' sung by Eric Halfvarson"/>

          <Header title="Bibliography"/>

          <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default VoicesPage
